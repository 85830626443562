import { Button } from "@components/ui/button";
import { useLogo } from "@lib/utils";
import Image from "next/image";
import Link from "next/link";

export default function Custom404() {
  const logo = useLogo();
  return (
    <div className="h-screen bg-gray-100 dark:bg-gray-800 w-full flex flex-col items-center justify-center text-center text-foreground">
      <div className="h-7 ">
        <Image width={166.4} height={28} alt="logo" src={logo} priority />
      </div>
      <h2 className="h2 mt-12 text-center">404 – Page Not Found</h2>
      <Link href="/" passHref className="mt-8">
        <Button variant="primary">Back to the Dashboard</Button>
      </Link>
    </div>
  );
}
